// Your web app's Firebase configuration

import {initializeApp} from "firebase/app";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {doc, getFirestore, setDoc} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA5d_ibsB4gcFWeqy6zP5imEISV7f3ImWw",
  authDomain: "emit-positivity.firebaseapp.com",
  projectId: "emit-positivity",
  storageBucket: "emit-positivity.appspot.com",
  messagingSenderId: "950714521558",
  appId: "1:950714521558:web:2936640cba6dd592e13677"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LerT7QdAAAAACM1rk4otXohEDDMGX7rlsWWrjUp'),
  isTokenAutoRefreshEnabled: true
});

const db = getFirestore(app);

const btn = document.querySelector('#addNews button');
const text = document.querySelector('#addNews .congratulation-form');
const sendMoreBtn = document.querySelector('.send-more');

const congratulation = () => {
  btn.style.display = 'none';
  text.style.fontSize = '22px';
  sendMoreBtn.style.fontSize = '16px';

  form.reset()
}

sendMoreBtn.addEventListener('click', () => {
  btn.style.display = 'inline-block';
  text.style.fontSize = '0';
  sendMoreBtn.style.fontSize = '0';
})

const form = document.querySelector('#addNews');
let isBlockedSubmitBtn = false;

document.querySelector('#submitBtn').addEventListener('click', (evt) => {
  const ch = document.querySelector('#access');

  if (!ch.validity.valid) {
    ch.classList.add('has-error')
  } else {
    ch.removeAttribute('class');
  }
})

form.addEventListener('submit', (evt) => {
  evt.preventDefault();

  const inputs = evt.target.elements;
  const payload = {
    email: inputs['email'].value,
    contact: inputs['contact'].value,
    article: inputs['article'].value,
  }

  const timestamp = new Date().getTime()
  if (!isBlockedSubmitBtn) {
    isBlockedSubmitBtn = true;

    setDoc(doc(db, "articles", `${payload.email}_${timestamp}`), {
      ...payload
    }).then(() => {
      console.log("Document successfully written!");
      isBlockedSubmitBtn = false;
      congratulation()
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
        isBlockedSubmitBtn = false;
      });
  }

//  8ED5574D-1F64-46F0-9353-8F9069260764
})
